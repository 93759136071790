.Parentloader {
  height: 100%;
  display: flex;
  top: 0;
  width: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.loader4 {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 3px solid;
  border-top-color: rgb(12, 150, 255) !important;
  border-bottom-color: rgba(187, 186, 186, 0.17) !important;
  border-left-color: rgb(12, 150, 255) !important;
  border-right-color: rgba(187, 186, 186, 0.17) !important;
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}
@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader4 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
